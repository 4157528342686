//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";

export default {
  name: "PinView",
  props: {
    pinView: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      slide: 1,
    };
  },
  computed: {
    ...mapGetters({
      category: "categories/getCategoryById",
    }),
  },
  methods: {
    maskPhone(phone) {
      var BRNumber = phone.toString().match(/(\d{2})(\d{5})(\d{4})/);
      return (BRNumber =
        "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3]);
    },
  },
};
