//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'MenuTopicsList',
    computed: {
      listTopics() {
        return this.$store.getters['topics/getRecents'];
      },
  },
}
