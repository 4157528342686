//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  LMap,
  LTileLayer,
  LMarker,
  LControlZoom,
  LPopup,
  LIcon,
} from 'vue2-leaflet';
import { mapGetters } from 'vuex';
import { gsap, TweenMax, Expo } from 'gsap';
import { createHelpers } from 'vuex-map-fields';
import PinView from '../components/PinView.vue';
import MobileMenu from '../components/MobileMenu.vue';
import MyMenu from '../components/Menu.vue';
import MyFilter from '../components/Filter.vue';

const { mapFields } = createHelpers({
  getterType: 'maps/getField',
  mutationType: 'maps/updateField',
});

gsap.registerPlugin(TweenMax, Expo);

export default {
  name: 'HomePage',
  title: 'Cartografia da Cultura de Campo Grande',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlZoom,
    LPopup,
    LIcon,
    PinView,
    MyMenu,
    MyFilter,
    MobileMenu
  },
  data() {
    return {
      layers: {
        carto: {
          url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
        },
      },
      iconSet: {
        iconSize: [30, 30],
        iconAnchor: [15, -8],
      },
      popupOptions: {
        autoPan: false,
      },
      filterSelections: [],
      drawerRight: false,
    };
  },
  computed: {
    ...mapFields({
      center: 'mapOptions.center',
      bounds: 'mapOptions.bounds',
      attribution: 'mapOptions.attribution',
      zoom: 'zoomSet.zoom',
      minZoom: 'zoomSet.minZoom',
      maxZoom: 'zoomSet.maxZoom',
      options: 'zoomSet.options',
    }),
    ...mapGetters({
      pins: 'pins/loadPinsFiltered',
      markers: 'pins/getMarkers',
      isPinSelected: 'pins/getSelectedPinId',
    }),
  },
  methods: {
    openDefaultMarker(mapObject, item) {
      if ((item.id === this.$store.getters['pins/getSelectedPinId'])) {
        mapObject.openPopup();
        this.$store.commit('pins/SET_SELECTED_PIN_ID', null);
      }
    },
    filterThis(el) {
      if (this.filterSelections.includes(el)) {
        const index = this.filterSelections.indexOf(el.toString());
        this.filterSelections.splice(index, 1);
      } else {
        this.filterSelections.push(el.toString());
      }
    },
    setCoordinates() {
      this.pins.forEach((item) => this.markers.push({ id: item.id, categoryId: item.categoryId, coordinates: [item.lat, item.long] }));
    },
    getPinById(id) {
      const target = this.pins.find((item) => item.id === id);
      return target;
    },
    pinClick({type}, pin) {
      /* usado para poder abrir em prioridade na versao mobile */
      if(type === "popupopen"){
        this.$store.commit('pins/SET_SELECTED_PIN_ID', pin.id);
      }
      else {
        this.$store.commit('pins/SET_SELECTED_PIN_ID', null);
      }
    }
  }
};
