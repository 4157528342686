//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MenuEventsList',
  computed: {
    listEvents() {
      return this.$store.getters['events/getRecents'];
    },
  },
  methods: {
    async openEvent(eventId) {
      this.$router.push({
        name: "Agenda",
        hash: `#${eventId}`
      });
    },
  },
}
